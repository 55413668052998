import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getLocalStorageItem } from "../utils/localStorage";

const businessLocationDetails = getLocalStorageItem("businessLocation") ?? [
  {
    _id: "6666edc66d4a518698da57f3",
    name: "Sydney",
    business: "6659ac2fe012d830266266be",
    __v: 0,
  },
];

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      headers.set(
        "Authorization",
        `Bearer ${token?.replace(/^"(.*)"$/, "$1")}`
      );
      headers.set("ngrok-skip-browser-warning", "69420");
    }
    return headers;
  },
});

const enhancedBaseQuery = async (args, api, extraOptions) => {
  const url = new URL(args.url, process.env.REACT_APP_BACKEND_URL);
  const pathnameSegments = url.pathname.split("/").filter((segment) => segment);

  if (pathnameSegments[0] === "auth") {
    return baseQuery(args, api, extraOptions);
  }

  if (args.method === "POST") {
    if (pathnameSegments[0] === "upload") {
      return baseQuery(args, api, extraOptions);
    } else {
      if (businessLocationDetails[0]?._id) {
        const modifiedArgs = {
          ...args,
          body: {
            ...args.body,
            businessLocation: businessLocationDetails[0]?._id,
          },
        };
        return baseQuery(modifiedArgs, api, extraOptions);
      }
    }
  } else {
    if (pathnameSegments[0] === "business-location") {
      if (args.method !== "PATCH") {
        pathnameSegments.splice(1, 1);
      }
    } else if (pathnameSegments[0] === "calendar") {
      pathnameSegments.splice(1, 0, businessLocationDetails[0]?._id);
    } else {
      pathnameSegments.splice(1, 0, businessLocationDetails[0]?._id);
    }

    url.pathname = "/" + pathnameSegments.join("/");
    const modifiedArgs = {
      ...args,
      url: url.pathname + url.search,
    };

    const result = await baseQuery(modifiedArgs, api, extraOptions);

    if (
      result.error &&
      result.error.data &&
      result.error.data.message === "Token expired"
    ) {
      window.location.href = "/login";
      localStorage.clear();
    }
    if (
      result.error &&
      result.error.data &&
      result.error.data.message === "User not found"
    ) {
      window.location.href = "/login";
      localStorage.clear();
    }

    return result;
  }
};

export const baseAPI = createApi({
  reducerPath: "baseAPI",
  baseQuery: enhancedBaseQuery,
  endpoints: () => ({}),
});
